import React, { useState } from 'react';
import 'typeface-roboto';
import './styles.css';
import Slider from '@material-ui/core/Slider';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Divider, Link } from '@material-ui/core';
import Brightness5Icon from '@material-ui/icons/Brightness5Outlined';
import Brightness4Icon from '@material-ui/icons/Brightness4Outlined';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 600,
    margin: 'auto auto',
    fontFamily: 'Roboto',
  },
  ev: {
    textAlign: 'center',
    fontSize: '3rem',
    marginBottom: theme.spacing(6),
  },
  margin: {
    height: theme.spacing(3),
  },
  sliderDescription: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-between',
    height: 40,
    lineHeight: 40,
  },
  sliderTitle: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.action.disabled,
    fontWeight: 300,
  },
  sliderHead: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 300,
  },
  exposureEv: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    fontWeight: 300,
  },
  pageTitle: {
    marginTop: theme.spacing(6),
    fontSize: '3rem',
    textAlign: 'center',
  },
  pageLink: {
    display: 'block',
    textAlign: 'center',
    marginTop: theme.spacing(6),
    textTransform: 'uppercase',
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 300,
  },
}));

const apertureMarks = [
  {
    value: -2,
    label: 'f/1.0',
  },
  {
    value: -1,
    label: 'f/1.4',
  },
  {
    value: 0,
    label: 'f/2',
  },
  {
    value: 1,
    label: 'f/2.8',
  },
  {
    value: 2,
    label: 'f/4',
  },
];

const isoMarks = [
  {
    value: -2,
    label: 'ISO 100',
  },
  {
    value: -1,
    label: 'ISO 200',
  },
  {
    value: 0,
    label: 'ISO 400',
  },
  {
    value: 1,
    label: 'ISO 800',
  },
  {
    value: 2,
    label: 'ISO 1600',
  },
];

const timeMarks = [
  {
    value: -2,
    label: '1/800',
  },
  {
    value: -1,
    label: '1/400',
  },
  {
    value: 0,
    label: '1/200',
  },
  {
    value: 1,
    label: '1/100',
  },
  {
    value: 2,
    label: '1/50',
  },
];

const exposureMarks = [
  {
    value: -6,
    label: '-6EV',
  },
  {
    value: -5,
    label: '-5EV',
  },
  {
    value: -4,
    label: '-4EV',
  },
  {
    value: -3,
    label: '-3EV',
  },
  {
    value: -2,
    label: '-2EV',
  },
  {
    value: -1,
    label: '-1EV',
  },
  {
    value: 0,
    label: '0EV',
  },
  {
    value: 1,
    label: '+1EV',
  },
  {
    value: 2,
    label: '+2EV',
  },
  {
    value: 3,
    label: '+3EV',
  },
  {
    value: 4,
    label: '+4EV',
  },
  {
    value: 5,
    label: '+5EV',
  },
  {
    value: 6,
    label: '+6EV',
  },
];

export default function App() {
  const [aperture, setAperture] = useState(0);
  const [time, setTime] = useState(0);
  const [iso, setIso] = useState(0);
  const [exposure, setExposure] = useState(0);

  const classes = useStyles();

  const exposureCalc = -aperture + time + iso;

  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.pageTitle}>
        Symulator ekspozycji
      </Typography>
      <Link
        className={classes.pageLink}
        href="https://www.kamilwysocki.com"
        target="_blank"
        rel="noopener"
      >
        www.kamilwysocki.com
      </Link>
      <div className={classes.exposureEv}>
        <Typography variant="h1" className={classes.ev}>
          {exposureCalc > 0 ? '+' : ''}
          {exposureCalc} EV
        </Typography>

        <Slider
          value={-aperture + time + iso}
          step={1}
          valueLabelDisplay="off"
          marks={exposureMarks}
          max={6}
          min={-6}
          track={false}
          disabled={true}
          onChangeCommitted={(event, value) => setExposure(value)}
        />
      </div>

      <Divider />

      <div className={classes.sliderDescription}>
        <Brightness5Icon fontSize="small" height="40px" />
        <Typography
          component="span"
          variant="overline"
          className={classes.sliderTitle}
        >
          mniejsza
        </Typography>
        <Typography
          component="span"
          variant="button"
          className={classes.sliderHead}
        >
          przysłona
        </Typography>
        <Typography
          component="span"
          variant="overline"
          className={classes.sliderTitle}
        >
          większa
        </Typography>
        <Brightness4Icon fontSize="small" />
      </div>
      <Slider
        defaultValue={aperture}
        step={1}
        marks={apertureMarks}
        valueLabelDisplay="off"
        max={2}
        min={-2}
        track={false}
        onChangeCommitted={(event, value) => setAperture(value)}
      />
      <div className={classes.sliderDescription}>
        <Brightness4Icon fontSize="small" />
        <Typography
          component="span"
          variant="overline"
          className={classes.sliderTitle}
        >
          krótszy
        </Typography>
        <Typography
          component="span"
          variant="button"
          className={classes.sliderHead}
        >
          czas naświetlania
        </Typography>
        <Typography
          component="span"
          variant="overline"
          className={classes.sliderTitle}
        >
          dłuższy
        </Typography>
        <Brightness5Icon fontSize="small" />
      </div>
      <Slider
        defaultValue={time}
        step={1}
        marks={timeMarks}
        valueLabelDisplay="off"
        max={2}
        min={-2}
        track={false}
        onChangeCommitted={(event, value) => setTime(value)}
      />
      <div className={classes.sliderDescription}>
        <Brightness4Icon fontSize="small" />
        <Typography
          component="span"
          variant="overline"
          className={classes.sliderTitle}
        >
          mniejsza
        </Typography>
        <Typography
          component="span"
          variant="button"
          className={classes.sliderHead}
        >
          czułość
        </Typography>
        <Typography
          component="span"
          variant="overline"
          className={classes.sliderTitle}
        >
          większa
        </Typography>
        <Brightness5Icon fontSize="small" />
      </div>
      <Slider
        defaultValue={iso}
        step={1}
        marks={isoMarks}
        valueLabelDisplay="off"
        max={2}
        min={-2}
        track={false}
        onChangeCommitted={(event, value) => setIso(value)}
      />
    </div>
  );
}
